<template>
  <v-container>
    <h1 v-html="$t('segmentation_form_overview_title')"></h1>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-row class="row search spacer-md">
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @download="download" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @download="download" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-local-unit ref="search-local-unit" @download="download" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-segmentation-overview ref="search-segmentation-overview" @download="download" />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-buyer-organisation ref="search-buyer-organisation" @download="download" />
      </v-col>
    </v-row>
    <v-btn color="primary" @click="download"><span v-html="$t('segmentation_form_overview_download')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('segmentation_form_overview_reset')" /></v-btn>
  </v-container>
</template>
<script lang="ts">
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import { fileCreator, showError } from '@/services/axios'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLocalUnit from '@/components/searchCards/searchLocalUnit.vue'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import SearchSegmentationOverview from '@/components/searchCards/searchSegmentationOverview.vue'
import SearchBuyerOrganisation from '@/components/searchCards/searchBuyerOrganisation'
import { useSearchSegmentationStore } from '@/store/SearchSegmentationStore'
import { useSearchOrganisationStore } from '@/store/SearchOrganisationStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationForms_reports_overview',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items)
      ]
    }
  },
  components: {
    SearchDairy,
    SearchContact,
    SearchLocalUnit,
    SearchSegmentationOverview,
    SearchBuyerOrganisation
  },
  methods: {
    queryParams() {
      return {
        term: Term.stringify(this.term),
        formId: useSearchSegmentationStore().formId,
        statusFrom: useSearchSegmentationStore().statusFrom,
        statusTo: useSearchSegmentationStore().statusTo,
        correction: useSearchSegmentationStore().correction,
        validUntilSearchRangeMin: useSearchSegmentationStore().validFrom,
        validUntilSearchRangeMax: useSearchSegmentationStore().validUntil,
        periodId: useSearchSegmentationStore().periodId,
        formCode: useSearchSegmentationStore().formCode,
        modificationFrom: useSearchSegmentationStore().modificationFrom,
        modificationUntil: useSearchSegmentationStore().modificationUntil,
        buyerOrganisationId: useSearchOrganisationStore().BuyerOrganisation_id
      }
    },
    async download() {
      try {
        this.loading = true
        const response = await this.axios.get(apiURL + '/segmentationForms/reports/overview', {
          params: this.queryParams(),
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'segmentationFormOverview.xlsx')
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-dairy'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-local-unit'].reset()
      this.$refs['search-segmentation-overview'].reset()
      this.$refs['search-buyer-organisation'].reset()
    }
  }
})
</script>
